.new-card-preview01 .card {
    border-radius: 10px;
    overflow: visible;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 20px;
}
.new-card-preview01 .thumbnail-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-card-preview01 .circular-thumbnail {
    width: 165px;
    height: 165px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.new-card-preview01 .rating {
    color: #FFD700;
}
.new-card-preview01 .price {
    font-size: 1.75rem;
    font-weight: bold;
    color: #28a745;
}
.new-card-preview01 .ribbon {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 120px;
    height: 120px;
    overflow: hidden;
}

.new-card-preview01 .ribbon span {
    position: absolute;
    display: block;
    width: 180px;
    padding: 8px 0;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    left: -40px;
    top: 30px;
    transform: rotate(-45deg);
}
/* .new-card-preview01 .content-container {
    padding-left: 20px;
} */
.new-card-preview01 .badge-premium {
    background-color: #FFD700;
    color: #333;
    margin-left: 10px;
}