.custom-marker {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border: 1px solid #000;
    border-radius: 50%;
    padding: 5px; */
  }
  
  .marker-icon img {
    width: 30px; /* Adjust the size as needed */
    height: auto; /* Adjust the size as needed */
  }
  
  .marker-text {
    font-size: 12px; /* Adjust the font size as needed */
    margin-top: 2px;
    font-style: italic;
    font-weight: bold;
    color:red
  }