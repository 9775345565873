.advaiet_listingg .card_for_img{
    height: 200px;
    width: 290px;
    position: relative;
    border-radius: 15px;
    margin-top: 35px;
}

.advaiet_listingg .card_for_img img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}
.advaiet_listingg .desc_inside_card{
    background-color: #fff;
    color: #000;
    position: absolute;
    top: 80%;
    left: 25px;
    height:60px;
    width: 240px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    overflow: hidden;
    transition: top 0.3s ease-in-out;
}
.advaiet_listingg .desc_inside_card p{
    padding: 10px;
    margin-bottom: 5px;
    width: 80%;
    text-align: center;
}
.advaiet_listingg .desc_inside_card:hover {
    top: 75%;
}
.advaiet_listingg .desc_inside_card svg{
    position: absolute;
    top: 25%;
    right: 5%;
    fill: #ff8839;
    border-radius: 35px;
}