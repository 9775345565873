.about-area .about-left-image img{
    /* max-height: 50vh;
    max-width: 50%; */
    border-radius: 10px;
    text-align: center;
}
.about-left-image{
    margin-left: auto;
  margin-right: auto;
  /* width: 50%; */
}
.border{
    text-align: center;
    border: 1px solid #dee2e6!important;
}
.about-left-image img{
    border-radius: 4px;
    padding: 10px 10px;
}
.left_img_txt_desc{
    /* background-color: #0c694233; */
}
