.modal-dialog{
  
}

@media (min-width: 700px) {
  .modal-dialog-sm{
    width: 60% !important;
    max-width: 60% !important;
  }
}
@media (max-width: 700px) {
  .modal-dialog-sm{
    width: 90% !important;
    max-width: 90% !important;
    align-self: center;
  }
}
.modal-dialog .modal-header{
  background-color: #2896cb;
  color: #fff !important;
}
.modal-dialog .modal-header span{
  color:#fff !important
}
