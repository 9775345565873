.how-it-works .process-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
}

.how-it-works .process-line {
    height: 2px;
    background-color: #e9ecef;
    position: relative;
    top: 40px;
    z-index: -1;
}
.how-it-works h2{
    color: #259cd6;
    font-weight: bold;
}
.how-it-works .how-it-works {
    padding: 80px 0;
    text-align: center;
}

.how-it-works .step-text {
    font-size: 14px;
    margin-top: 10px;
    color: #495057;
}

.how-it-works h2 {
    margin-bottom: 60px;
    font-weight: 600;
}

.how-it-works .step-icon {
    color: #6c757d;
    font-size: 24px;
}