@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&amp;display=swap");
@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css); 
@import url(./assets/css/vendor/vendor.min.css);
@import url(./assets/css/plugins/plugins.min.css);
@import url(./assets/css/style.min.css);
@import url(./assets/css/aos.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

/* for beautify card */
/* @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bree+Serif&family=EB+Garamond:ital,wght@0,500;1,800&display=swap'); */

.product .thumb .add-to-cart{
    background-color: #4c9090;
    color:#FFFFFF;
}
.footer-area .footer-top{
    padding-bottom: 0px;
}
.updImg{
    display: none;
 }
 .product-thumbnail-custom img{
    height: 60px;
    width: 70px;
    border-radius: 10%;
  }
  .table>:not(caption)>*>* {
    border-bottom-width:0px;
  }
.updthumb{
    height: 50px;
    width: 50px;
}

/* CSS */
.button-37 {
  background-color: #13aa52;
  border: 1px solid #13aa52;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 10px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-37:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .button-37 {
    padding: 10px 30px;
  }
}
.g-box-shadow {
  box-shadow: 0 0 3px 0 rgb(2 2 2/26%);
  -webkit-box-shadow: 0 0 3px 0 rgb(2 2 2/26%);
}
.pe-3 {
  padding-right: 1rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}
.border-end {
  border-right: 1px solid #dee2e6 !important;
}

/* scroll bar webkit */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d65f25; 
  /* border-radius: 10px; */
  /* background: #f1f1f1; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1c4ea4;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}
/* end of webkit */
input {
  height: 40px;
}
select{
  height: 40px;
}
.btn{
  height: 40px;
  width: auto;
  padding: 10px 10px;
  /* line-height:0px; */
}
.form-control{
  /* min-height: 0px; */
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-row label{
  margin-top:auto;
}
.bg-danger{
  background-color: #dc3545!important;
}
.bg-color3{
  background-color: #099AB1;
}

.card-primary{
background-color: #007bff;
}
table {
  max-width: 100%!important;
  width: 100% !important;
  position: relative;
}
.dtgrid>thead {
  background-color: #a92e23;
  color: #fff;
}
.parent_tb {
  height: 10%;
  width: 100%;
  display: table;
}
.line_tb {
  display: table-cell;
  vertical-align: middle;
}
.left_tb {
  margin-left: 2%;
  float: left;
}
.right_tb {
  margin-right: 2%;
  float: right;
}
label{
  margin-bottom: 0px;
  font-weight: bold;
}
.formarea-sm  input[type=password] ,.formarea-sm select,.formarea-sm  input[type=text]{
  min-height: 40px;

}
input[type=checkbox], input[type=radio] {
  float: left;
  margin: 4px 4px;
  
  
  line-height: normal;
  height: 20px;
  width: auto;
}
.radio-inline, .checkbox-inline {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.input-group>.custom-file {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
  position: relative;
  -webkit-flex: 1 1;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
}
.custom-file, .custom-file-input {
  position: relative;
  width: 100%;
  height: calc(2.25rem + 2px);
}
.red{
  color: #a92e23;
}
.set-min-height{
  min-height: 90vh;
}
.form-control{
  min-height: 40px;
}
.less-border{
  box-shadow: 0 0 1px rgba(0,0,0,.1), 0 1px 1px rgba(0,0,0,.1) !important;
}
.no-input-border-sm {
  border:none;
  border-bottom: 1px solid #e45916;
}
.no-input-border-sm:focus{
  border:none;
  border-bottom: 1.5px solid #e45916;
}
@media (max-width: 700px) {
  .hide-mob{
  display: none;
  }
}

.b-radius5{
  border-radius: 5%;
}
.modal-dialog{
  margin: auto;
  text-align: center;
}
@keyframes animate-preloader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
#resultLoading{
  /* content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--accent-color);
  border-top-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;  */
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #eb5b0a;
  border-top-color: color-mix(in srgb, #3498db, transparent 90%);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}






