.post {
    border-bottom: 1px solid #adb5bd;
    color: #666;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.post .user-block {
    margin-bottom: 15px;
    width: 100%;
}
.user-block {
    float: left;
}
.user-block img {
    float: left;
    height: 40px;
    width: 40px;
}
.img-circle {
    border-radius: 50%;
}
.user-block .username {
    font-size: 16px;
    font-weight: 600;
    margin-top: -1px;
}
.user-block .comment, .user-block .description, .user-block .username {
    display: block;
    margin-left: 50px;
}
.user-block .description {
    color: #6c757d;
    font-size: 13px;
    margin-top: -3px;
}
.user-block .comment, .user-block .description, .user-block .username {
    display: block;
    margin-left: 50px;
}
.info-box {
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    border-radius: 0.25rem;
    background: #fff;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1rem;
    min-height: 80px;
    padding: 0.5rem;
    position: relative;
    width: 100%;
}
.info-box .info-box-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 120%;
    -ms-flex: 1;
    flex: 1;
    padding: 0 10px;
}
.info-box .info-box-text, .info-box .progress-description {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.info-box .info-box-number {
    display: block;
    margin-top: 0.25rem;
    font-weight: 700;
}
.info-box .info-box-icon {
    border-radius: 0.25rem;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.875rem;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 70px;
}
.bg-info, .bg-info>a {
    color: #fff!important;
}
.bg-success, .bg-success>a {
    color: #fff!important;
}
.bg-warning, .bg-warning>a {
    color: #1f2d3d!important;
}
.bg-danger, .bg-danger>a {
    color: #fff!important;
}