.hero-bg-image img{
    position: relative;
    height: auto;
    width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px){
    .slider-height{
        height: 500px;
    }
}
@media only screen and (max-width: 479px){
    .slider-height{
        height: 500px;
    }
}
.hero-slide-item{
    /* background-color: #e7e7e73b; */
    background-color: #f5ad800f;
    /* background-color: #17836e2b; */
}


/* animation logic */

@import url(https://fonts.googleapis.com/css?family=Exo:100);
@-webkit-keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @-moz-keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @-o-keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @-webkit-keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
  @-moz-keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
  @-o-keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
  @keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
.bg-rectangle-animation1{
    margin-top: 13.5rem;
    color: #e7e7e73b;
    background-color: #e7e7e73b;
    font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
    text-align: center;
    /* img size is 50x50 */
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC") repeat 0 0;
    -webkit-animation: bg-scrolling-reverse 0.92s infinite;
    /* Safari 4+ */
    -moz-animation: bg-scrolling-reverse 0.92s infinite;
    /* Fx 5+ */
    -o-animation: bg-scrolling-reverse 0.92s infinite;
    /* Opera 12+ */
    animation: bg-scrolling-reverse 0.92s infinite;
    /* IE 10+ */
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    animation-timing-function: linear;
}
.bg-rectangle-animation::before{
    
        content: "";
        font-size: 8rem;
        font-weight: 100;
        font-style: normal;
      
}
.align-self-right{
  align-self:right;
}
@media only screen and (min-width: 650px){
  .bg-banner-text{
    background-color: #194da11c;
    padding: 20px 65px;
    border-radius: 5%;
  }
}
@media only screen and (max-width: 600px){
  .bg-banner-text{
    background-color: #194da11c;
    padding: 20px 18px;
    border-radius: 5%;
  }
}

.bg-area-medico{
  background-image: url('../assets/images/bg_medical_1.jpg');
  background-size: cover;

}
.bg-area-medico2{
  background-image: url('../assets/images/bg_medical_testi_5.jpg');
  background-size: cover;

}
