.header-actions .header-action-btn .header-action-num{
    /* top: 12px; */
    /* right: 66px; */
}
.header-actions .header-action-btn{
    display: flex;
    /* flex-direction: column; */

}
@media only screen and (max-width: 600px){
    .header-actions .header-action-btn a{
        font-size: small;
    }
}
