.why-us .content {
padding: 30px;
    background: #4c9090;
    border-radius: 4px;
    color: #fff;
}
.why-us .icon-boxes .icon-box {
    text-align: center;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    padding: 40px 30px;
    width: 100%;
    transition: 0.3s;
}
.icon-box i{
    font-size: 24px;
    line-height: 1;
}