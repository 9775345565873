.single-contact-info .contact-info-inner{
    min-width: 80px !important;
}
#vehicle1, .sub-title {
    margin-right: 5px;
}
#vehicle1 {
    height: 15px;
    width: -webkit-min-content;
    width: min-content;
}
.contact-form{
    background-color: #fff;
}
.contact-info-wrap{
    border:#259cd645 5px solid;
    background-color: #44d6260d;
}