.parent_tb {
    height: 10%;
    width: 100%;
    /* background-color: grey; */
    /* font-size: 1.6em; */
    /* border-bottom: 1px solid #e1e1e1; */
    display: table;
}

.line_tb {
    display: table-cell;
    vertical-align: middle;
}

.left_tb {
    margin-left: 2%;
    float: left;
    /* background-color: yellow; */
}

.right_tb {
    margin-right: 2%;
    float: right;
    /* background-color: red; */
}
/* end tablecell */