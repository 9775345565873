.card{
   
    box-shadow: 0 6px 12px 0 rgba(0,0,0,0.2)
}
.dia_cart{margin-top: 60px;margin-bottom: 60px;}
.blue-text{color: #00BCD4}
.form-control-label{margin-bottom: 0}

/* input, textarea, button{box-sizing: border-box;border: 1px solid #ccc;} */

input:focus, textarea:focus{-moz-box-shadow: none !important;-webkit-box-shadow: none !important;box-shadow: none !important;border: 1px solid #00BCD4;outline-width: 0;font-weight: 400}
.btn-block{text-transform: uppercase;font-size: 15px !important;font-weight: 400;height: 43px;cursor: pointer}
.btn-block:hover{color: #fff !important}
button:focus{-moz-box-shadow: none !important;-webkit-box-shadow: none !important;box-shadow: none !important;outline-width: 0}
.form-check .form-check-input{
    margin-left: 0px;
    margin-top:2px;
}
.form-check-input[type=radio] {
    border-radius: 30%;
    margin-right: 2px;

}