/*body::-webkit-scrollbar {
        width: 1em;
      }
      body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      body::-webkit-scrollbar-thumb {
        background-color: #666;
        outline: 1px solid #eee;
      }*/

 /* item list compoent */
 .shop-cart-item{
       
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  border-radius: 8px !important;
  border: none !important;
  overflow: hidden !important;
}
.shop-cart-item .shop-cart-left {
  margin: 0 15px 0 0px;
  width: 82px;
  flex: none;
}
.font-w{
  font-weight: 600;
}
.shop-cart-right {
  width: 75%;
}
.shop-cart-right-button{
  width: 24%;
  padding: 20px 0;
}
.price span {
  font-size: 12px;
}
.mybtn:hover{
  background: #004226;
  color: #fff;
  border-color: #0c6942;
}
.mybtn:focus{
  background: #004226;
}
.mybtn {
  text-decoration: none;
  background: #0c6942;
  padding: 7px 12px;
  color: #fff;
  border-radius: 23px;
  bottom: 36px;
  border-color: #0c6942;
  float: right;
  display: flex;
  line-height: 13px;
  font-size: 14px;
  position: relative;
}
.shop-cart-left {
  margin: 0 15px 0 0px;
  width: 82px;
  flex: none;
}
.ms-auto {
  margin-left: auto!important;
}
 /* end of item list compoent */
      .scrollmenu {
        margin-bottom: 60px;
        border-bottom: 0;
        white-space: nowrap;
        display: inline-block;
        width: 100%;
        overflow-y: hidden;
    }
    .nav-tabs li {
        margin-right: 5px;
    }
    .nav-tabs>li {
        float: none;
        display: inline;
    }
    .nav-tabs {
        position: relative;
        z-index: 1;
    }
    
    .nav-tabs {
        margin-bottom: 10px;
        border-bottom: 0;
    }
    .nav-tabs>li>button.active, .nav-tabs>li button.active>:focus, .nav-tabs>li>button.active:hover, .nav-tabs>li>button:hover {
        border: none;
        background-color: #008def !important;
        color: #fff !important;
    }
    
    .nav-tabs>li button {
        display: inline-block !important;
        background-color: #e3e3e3 !important;
        border: none !important;
        border-radius: 30px !important;
        font-size: 14px !important;
        color: #000 !important;
        padding: 5px 30px !important;
        margin-bottom: 10px !important;
    }
    
    .nav-tabs:after {
        /*position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #e3e3e3;
        z-index: -1;*/
    }
    /* width */
    ::-webkit-scrollbar {
          height: 5px !important;
        }
    ::-webkit-scrollbar-track {
       -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }
    ::-webkit-scrollbar-thumb {
            background-color: #888;
            outline: 1px solid #eee;
          }
    ::-webkit-scrollbar-thumb:hover {
            background: #555; 
          }