.bgCard{
    background-color: #ffffffbf !important;
}
.white {
    color: #fff !important;
}
.black{
    color:#000 !important;
}
body{
    /* background-image: url(../assets/images/bg-3.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

      /* background-image: url('../assets/images/bg-2.jpg'); */
         /* background: #fff; */
    /* color:#7b0000; */
    /* background-position: center; */
    /* background-repeat: no-repeat;  */
    /* background-size: cover; */
    

   
    
}
.container, .container-lg, .container-md, .container-sm, .container-xl {
     max-width: 98%!important; 
}
.wrapper{
    /* opacity: 999; */
    /* background: #dcd7d747; */
}
section{
     /* background: #ffffffb0;  */
}
.text-red{
    color:red;
}
.green{
    background: #259CD6;
    /* background:#d65f25; */
}
.orange-for{
    color:#d65f25;
}
.main-menu.manu-color-white ul li a{
    color:#fff;
}
.category-wrapper .category-inner{
    border-radius: 20%;
}
.hero-bg-image img{
  
}
.hero_home .swiper-wrapper{
     /* max-height: 450px !important;  */
}
.hero-slide-content-2{
    /* background-color:  #4c90904d !important; */
    padding: 10px 10px;
    
}
.hero-slide-content.hero-slide-content-2{
    margin-top:20px;
    border-radius: 10px;
    min-height: 70vh;
    border-radius: 10px 20px 30px 40px/30px;
}
.banner-3 img{height:327px; }

.border-box{
    /* box-shadow: 2px 5px 13px 2px #6c757d; */
    /* box-shadow: 0px 0px 0px 1px #6c757d5e;
    border-radius: 20px; */
    padding: 20px;
    /* margin: 5px; */
     margin-top: 5px; 
    margin-bottom: 5px;
    /* background: #FFFFFF;  */
}
.border-box-2{
    text-align: center;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    /* padding: 40px 30px; */
    /* width: auto; */
    transition: 0.3s;
}
.bg-themecolor{
  background-color: #0c6942;
  color:#fff;
}
.bg-themecolor a h4{
    color:#fff !important;
}

.heading .title{
    color: #ea5602;
}
.h5,h5{
font-size: 1.5rem;
}
a {
    color:#271304;

}
.btn-primary {
    color: #fff;
    background-color: #ff7004 !important;
    border-color: #ff7004 !important;
    border: 0;
    transition: all .3s ease 0s;
    
}
h2{
    color: #006338 !important;
}
.title.h3{
    color: #006338 !important;
    font-size: 1.3rem !important;
}
.h3 h3{
    font-size: calc(1rem + .6vw) !important;
}
.submit{
    /* background-color:#4c9090 !important; */
}

.heading>.title:after {
    content: "";
    background: no-repeat;
    width: 100%;
    height: 2px!important;
    /* background-color: #259cd6; */
    position: relative;
    margin: auto;
    display: block;
    text-align: left;
    float: left;
}
.slider-nav-style-1 .swiper-buttons .swiper-button-next, .slider-nav-style-1 .swiper-buttons .swiper-button-prev{
background-color: #ec802e;

}
.pt-50px{
padding-top: 40px;
}
.pb-50px{
    padding-bottom: 40px;

}
.pb-70px{
    padding-bottom: 70px;
}
.pt-50{
    padding-top: 40px;
    }
    .pb-50{
        padding-bottom: 40px;
    
    }
.product:hover .thumb .image img {
    transform: scale(1.1);
}
.product .thumb .image img{
    height: 100%;
    width: auto;
    max-width: 100%;
    border-radius: 10px;
}
#medicine_fet .row{
    --bs-gutter-y:30px;
}
.small_container{
    max-width: 80%; margin: auto;
}
.linkclass {
    color: #14955f!important;
    font-weight: 700;
}

.bg_fram{
    position: relative; 
  /* height: 100vh;
  width: 100%; */
  
  align-items: center;
  justify-content: center;
    
    &::before {  
        content: "";
        background-image: url('../assets/images/small_medical.webp');
        background-size: cover;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.5;
        background-repeat: no-repeat;
    }
    

}
form input,select,textarea {
    /* margin-top: 10px; */
}
.pt-20px {
    padding-top: 20px;
}
.pb-20px {
    padding-bottom: 20px;
}