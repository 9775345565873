.hospital-section {
    /* max-height: 320px; */
    overflow: hidden;
    background: #ffffff;
    padding: 15px;
    }
    .hospital-info .hospital-card {
      max-width: 350px;
      padding: 24px;
      border-radius: 8px;
      background-color: #FFF5EB;
      box-shadow: 0 4px 12px rgba(255, 153, 51, 0.15);
      font-family: 'Arial', sans-serif;
      border-right: 5px solid #FF9933;
    }
    
    .hospital-info .hospital-card h3 {
      color: #FF7F00;
      margin-top: 0;
      font-size: 22px;
      margin-bottom: 15px;
    }
    
    .hospital-info .info-item {
      display: flex;
      margin-bottom: 12px;
      align-items: flex-start;
    }
    
    .hospital-info .info-label {
      font-weight: bold;
      color: #666;
      margin-right: 8px;
      min-width: 70px;
    }
    
    .hospital-info .info-content {
      color: #333;
      margin: 0;
    }
    
    .hospital-info .hospital-card a {
      color: #FF7F00;
      text-decoration: none;
    }
    
    .hospital-info .hospital-card a:hover {
      text-decoration: underline;
    }
    
    .hospital-info .hours-badge {
      display: inline-block;
      background-color: #FFE0B2;
      color: #FF7F00;
      padding: 3px 8px;
      border-radius: 12px;
      font-size: 13px;
      font-weight: bold;
    }
    @media (max-width: 900px) {
    .hospital-section {
        max-height: none ; 
       
    } 
}