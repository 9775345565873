.feature_web img{
    /* width: 60%; */
    height: auto;
    float: right;
    width: 200px;
    height: 180px;
}
.feature_web .title{
    color:white !important;
}
.feature_web a{
    color:white !important;
}
.feature_web_bg {
    background-image: url(../assets/images/banner/bg_feature.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.feature_web{
    
    border-radius: 10px;
    color: white !important;
    background-color: #4e8d73;
    /* background-image: linear-gradient(bottom, #FFD51A 50%, #FAC815 50%);
    background-image: -o-linear-gradient(bottom, #FFD51A 50%, #FAC815 50%);
    background-image: -moz-linear-gradient(bottom, #FFD51A 50%, #FAC815 50%);
    background-image: -webkit-linear-gradient(bottom, #FFD51A 50%, #FAC815 50%);
    background-image: -ms-linear-gradient(bottom, #FFD51A 50%, #FAC815 50%); */
}