.vendor_thumb img{
    max-height: 200px;
    width: auto;
}
.mybg{
    color: #222 !important;
    border-radius: 10px 0;
}
.mybtn {
    text-decoration: none;
    background: #0c6942;
    /* padding: 5px 28px; */
    color: #fff;
    /* margin-bottom: 10px; */
    border-color: #0c6942;
}
.mybtn:hover {
    background: #004226;
    color: #fff;
    border-color: #0c6942;
}
.mybtn:focus{
    background: #004226;
}