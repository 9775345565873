.breadcrumb-area {
    background-color: rgb(238 118 0);
    background-repeat: no-repeat;
    background-position: 50%;
    border-bottom-width: 0;
    background-size: cover;
    padding-top: 0px;
    padding-bottom: 0px;
}
.breadcrumb_box h2{
    color: #fff !important;
}
.breadcrumb_box a{
    color: #fff;
    font-size: 20px;
}
.mobile_footer{
    position: fixed;
  bottom: 0px;
  width: 100%;
  /* negative value of footer height */
  
  clear: both;
  
}
@media only screen and (max-width: 600px){
    .h6, h6{
        font-size: 0.7rem;
    }
    .breadcrumb-list{
        margin-top: 5px;
    }
    .breadcrumb_box a{
        color: #fff;
        font-size: 18px;
    }
    
}
.updImgThumb{
    display: none;
}
.imgLibThumb img {
    float: left;
    object-fit: cover;
}
.image_thumb_parent img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
    height: auto;
}
.image_thumb_parent .icon-close {
     position: absolute;
    color: #000;
    top: 0px;
    right: 0px;
    z-index: 9;
    background: #fff;
    font-size: 16px; 
    float:right
}

/* for activation proc api */
.card-header:first-child {
    border-radius: calc(.25rem - 0) calc(.25rem - 0) 0 0;
}

.card-header {
    background-color: transparent;
    border-bottom: 1px solid rgba(0,0,0,.125);
    padding: 0.75rem 1.25rem;
    position: relative;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 0 solid rgba(0,0,0,.125);
}
.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}
.product-list-in-card>.item {
    border-radius: 0;
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.products-list>.item {
    border-radius: 0.25rem;
    background: #fff;
    padding: 10px 0;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
.products-list .product-img {
    float: left;
}
.products-list .product-info {
    margin-left: 60px;
}
.products-list .product-description {
    color: #6c757d;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.float-right {
    float: right!important;
}
.badge-warning {
    color: #1f2d3d;
    background-color: #ffc107;
}
.badge-info {
    color: #fff;
    background-color: #17a2b8;
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.products-list .product-title {
    font-weight: 600;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.btn-tool {
    background: 0 0;
    color: #adb5bd;
    font-size: .875rem;
    margin: -0.75rem 0;
    padding: 0.25rem 0.5rem;
}
/* .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */

.gion .fa {
    width: 20px;
    color: #055094;
}