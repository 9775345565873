.work-section {
    position: relative;
    padding: 20px 20px;
    background-color: #5db6ff;
    color: white;
    text-align: center;
}

.work-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.work-section .container {
    position: relative;
    margin: 0 auto;
    z-index: 2;
}

.work-section .section-title {
    margin-bottom: 60px;
}

.work-section .section-title h1 {
    text-transform: capitalize;
    margin-bottom: 20px;
}

.work-section .work-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 130px;
    padding: 20px;
}

.work-step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    position: relative;
}

.work-section .work-step {
    width: 115px;
    height: 115px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    transform: rotate(45deg);
    margin-bottom: 15px;
}

.work-section .work-step:hover {
    background-color: #f19640;
}

.work-section .work-step-content {
    transform: rotate(-45deg);
    text-align: center;
    color: #706f6d;
}

.work-section .work-step:hover .work-step-content {
    color: white;
}

.work-section .work-step svg {
    font-size: 30px;
    margin-bottom: 5px;
    /* display: block; */
}

.work-section .work-step span {
    font-size: 12px;
    font-weight: 700;
    display: block;
}

.detail-description-box {
    font-size: 12px;
    padding: 5px;
    max-width: 150px;
    word-wrap: break-word;
    text-align: center;
}

@media (max-width: 768px) {
    .work-section .work-steps {
        gap: 20px;
    }
    
    .work-step-container {
        width: 120px;
    }

    .work-section .work-step {
        width: 90px;
        height: 90px;
    }

    .work-section .work-step i {
        font-size: 24px;
    }

    .work-section .work-step span {
        font-size: 12px;
    }

    .detail-description-box {
        font-size: 11px;
        max-width: 100px;
    }
}