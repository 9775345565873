.mycard {
    width: calc(100% - 30px);
    /* height: calc(27vw - 20px); */
    /* border-radius: 20px 20px 20px 0px; */
    border-radius: 5px;
    width: calc(100%);
    height: auto;
    background: white;
    /* color: #333; */
    margin-right: 20px;
    display: block;
    text-align: left;
    padding: 23px 17px 0px;
    position: relative;
    margin-bottom: 20px;
}
.title-link, .card-title {
    text-decoration: none;
    color: #00aaef;
    font-size: 25px;
}
.mybtn {
    position: absolute;
    text-decoration: none;
    background: #0c6942 !important;
    padding: 0px 22px;
    color: #fff;
    border-radius: 23px;
    bottom: 36px;
    border-color: #0c6942;
    width: auto !important;
}
.orange-bg{
    background-color: #f0edeca3;
}
.page__title-bar_testi{
    display: flex;
    align-items: center;
    justify-content: center;
}
.testi_img{
    height: 50px;
    width: 50px;
    position: relative;
    left: 5%;
    /* top: -30px; */
}

.testi_img_center{
    height: 50px;
    width: 50px;
    position: absolute;
    left: 46%;
    top: -30px;
}


.rating-product i {
    color: #fdd835;
    overflow: hidden;
    width: 16px;
    font-size: 16px;
}
.rating-product i {
    font-size: 26px!important;
}
.title_testimonical span{
    display: block;
    font-size: small;
}