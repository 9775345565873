.custom-box-circular-icon {
    width: 100%;
    background-color: #fff;
    padding: 50px 20px;
    text-align: center;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.custom-box-circular-icon h6{
    color:  #259cd6;
}
.custom-box-circular-icon .icon-wrapper {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.custom-box-circular-icon .icon-wrapper img{
    height: 90px;
    border-radius: 50%;
}