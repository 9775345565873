.weekDays-selector input[type=checkbox]:checked + label {
    background: #ffc107;
    color: #ffffff;
}
.weekDays-selector input[type=checkbox] + label {
    display: inline-block;
    border-radius: 40px;
    background: #dddddd;
    height: 40px;
    width: 40px;
    margin-right: 3px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
}
.weekDays-selector input {
    display: none!important;
}