.breadcrumb-area{
    padding-top: 40px;
    padding-bottom: 40px;
}
/*
.inner-banner{
    filter: blur(8px);
    -webkit-filter: blur(8px);
    height: 100%;

  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
*/
 .banner-bg-text {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    /* border: 3px solid #f1f1f1; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
  }