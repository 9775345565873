.custome-flex{
    display: flex;
    flex-direction: row;
}
div.carditem_n1{
    width: 100%;
    /* height: 200px; */
    justify-content: space-between;
    background-color: #fff;
    color: #000;
    border: 1px solid  #dfe3e6;
    border-radius: 6px;
    /* for chk of side img */
    position: relative;
    z-index: 22;
   
}
div.carditem_n1:hover{
    transform: scale(1.01);
    box-shadow: 1px 0 10px 10px rgb(0 0 0 / 20%);
}
.row1{
    background-color:rgb(76 144 144 / 75%);
    /* background-color: rgba(53,194,169,.24); */
    /* background-color:rgb(214 95 37 / 76%); */
    height: 60%;
    color:#fff;
    font-size: medium;
    border-radius: 6px;
    /* color: #000; */

}
.row2{
    background-color: #fff;
    align-items: baseline;
    vertical-align:center;
    padding-top: 15px;
    padding-left:20px;
    padding-bottom: 15px;
   
}
.imgtag{
    position: absolute;
   background-size: 100%;
   padding-left: 5px;
   width: 150px;
   color: #fff;
   font-size: medium;
   font-weight: 400;

}
.feature_card1{
    margin-top: 5px;
}
@media only screen and (max-width: 600px){
    /* For portrait layouts only */
    .boxname{
        align-items: flex-end;
        clear: both;
        /* height: 115px; */
        font-weight: 700;
        font-size: medium; 
        /* color: rgb(75, 71, 71); */
        padding: 40px 10px;
        outline: none;
        vertical-align: baseline;
        z-index: 222;
        
    }
    div.sideimg{
        top: 0;
        right:0;
        height: 100%;
        position:absolute;
        width: 96px;
        z-index:10;  
        border-style: none;
        justify-content: flex-end;
      
           /* float: ;         */
           
        /* */
        /* display: inline-flexbox;
        -webkit-box-align: start;
        -webkit-box-pack: end;  */
    }
    /* .sideimg img{
        max-width: 50%;
        max-height: 50vh;
    } */
  }
  @media only screen and (min-width: 600px){
    /* For portrait layouts only */
    .boxname{
        align-items: flex-end;
        clear: both;
        /* height: 115px; */
        font-weight: 700;
        font-size: large; 
        /* color: rgb(75, 71, 71); */
        padding: 40px 10px;
        outline: none;
        vertical-align: baseline;
        z-index: 222;
        
    }
    div.sideimg{
        top: 0;
        right:0;
        height: 100%;
        position:absolute;
        width: 186px;
        z-index:10;  
        border-style: none;
        justify-content: flex-end;
      
           /* float: ;         */
           
        /* */
        /* display: inline-flexbox;
        -webkit-box-align: start;
        -webkit-box-pack: end;  */
    }
    /* .sideimg img{
        max-width: 100%;
        max-height: 100vh;
    } */
  }

.button1{
    height: 45px;
    width: 160px;
   border-radius: 4px;
   border-style: none;
    background-color:#3bb896;
    color: #fff;
}
div.sideimg img{
    bottom: 0;
    right: 0;
   position: absolute;
   width: 40%;
   z-index: -1;
   
   /* float: right;
   clear: right;
   display:flex;
   align-items:flex-end; */
}
 
.feature_card_n1_bg {
    background-image: url(../assets/images/banner/feature_card_n1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.order-icon {
    font-size: large;
    margin-right: 20px;
}