.advaiet-benefit-section .benefit-card {
    padding: 15px;
    margin-bottom: 20px;
    height: 100%;
    text-align: center;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    margin-top: 20px;
}
.advaiet-benefit-section h2{
    color: #259cd6;
}
.advaiet-benefit-section .row .col-md-4 {
    margin-top: 20px;
}
.advaiet-benefit-section .check-icon {
    color: #d17e4e;
    font-weight: bold;
    margin-right: 10px;
}

.advaiet-benefit-section .benefit-title {
    font-weight: 600;
    margin-bottom: 15px;
    /* display: flex; */
    align-items: center;
    color: #664430;
}

.advaiet-benefit-section .benefit-description {
    color: #6c757d;
    font-size: 14px;
}

.advaiet-benefit-section .main-title {
    margin-bottom: 40px;
    font-weight: bold;
}