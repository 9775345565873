div.carditem{
    width: 100%;
    height: 200px;
    justify-content: space-between;
    background-color: #fff;
    color: #000;
    border: 1px solid  #dfe3e6;
    border-radius: 6px;
    /* for chk of side img */
    position: relative;
    z-index: 1;
   
}
div.carditem:hover{
    transform: scale(1.01);
    box-shadow: 1px 0 10px 10px rgb(0 0 0 / 20%);
}
.row1{
    /* background-color: rgba(53,194,169,.24); */
    background-color:rgb(76 144 144 / 75%);
    height: 60%;
    color:#fff;
    font-size: medium;
    /* color: #000; */

}
.row2{
    background-color: #fff;
    align-items: baseline;
    vertical-align:center;
    padding-top: 15px;
    padding-left:20px;
    padding-bottom: 15px;
   
}
.imgtag{
    position: absolute;
   background-size: 100%;
   padding-left: 5px;
   width: 150px;
   color: #fff;
   font-size: medium;
   font-weight: 400;

}
.boxname{
    align-items: flex-end;
    height: 115px;
    font-weight: 700;
    font-size: large; 
    /* color: rgb(75, 71, 71); */
    padding: 50px 25px;
    outline: none;
    vertical-align: baseline;
}
.button1{
    height: 45px;
    width: 160px;
   border-radius: 4px;
   border-style: none;
    background-color:#3bb896;
    color: #fff;
}
  div.sideimg{
    top: 0;
    right:0;
    height: 100%;
    position:absolute;
    width: 186px;
    z-index:10;  
    border-style: none;
    justify-content: flex-end;
  
       /* float: ;         */
       
    /* */
    /* display: inline-flexbox;
    -webkit-box-align: start;
    -webkit-box-pack: end;  */
}