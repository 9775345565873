.flx_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  .hover-image:hover{
    transform: scale(1.1);
  }
  
  
  .col {
    flex: 1;
    /* background: orange; */
    margin: 1px;
    display: flex;
    justify-content: center;
  }
  
  .col-item {
    flex: 1;
    /* margin: 15px; */
  }
/*myblocks css*/
.box {
    display: flex;
    flex-direction: column;
    transition: all ease 0.4s;
    height: auto;
    border-radius: 5px;
    padding: 13px 16px;
    background: #ffffff;
    margin-bottom: 30px;
    box-shadow: 0 0 3px 0px rgb(2 2 2 / 26%);
    -webkit-box-shadow: 0 0 3px 0px rgb(2 2 2 / 26%);
    padding-bottom: 0px;
    
}
.box>img{
    margin-bottom: auto;
}
.bg-purple{
    background-color: #a020f0 !important;
}
.badge{
    text-transform: uppercase;
    border-radius: 4px;
}
.myp{
    font-size: 14px;
    /* color: #b7b4b4; */
    color:#5f5757;
    margin-bottom: 0;
}