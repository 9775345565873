.ptrmain_div{
    width: fit-content;margin-right: auto;margin-left: auto;
    margin-top: 30px;
}
.prt_head_div{
    display: flex;flex-wrap: wrap;
}
.ptrTable{
    border-collapse: collapse !important;
    font-family: Verdana, Geneva, sans-serif !important;
    width: 100% !important;
}
.ptrTable>tr>th{
    border: 1px solid #222 !important;
    padding: 6px 10px !important;
}
.ptrTable tr td{
    border: 1px solid #222 !important;
    padding: 6px 10px !important;
}
