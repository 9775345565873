#faq .panel-body{
    padding:10px 10px;
    
}
#faq .panel{
    /* width: 60%; */
}
.collapse_pan img{
    max-width: 40%;
    height: auto;
    border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}
.no-border{
    border:none !important;
}